import "./src/styles/globals.css"

import React from "react"
import { RecoilRoot } from "recoil"

import { QueryClient, QueryClientProvider } from "react-query"

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
  const queryClient = new QueryClient()
  return (
    <RecoilRoot {...props}>
      <QueryClientProvider client={queryClient}>{element}</QueryClientProvider>
    </RecoilRoot>
  )
}
